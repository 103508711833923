.job-signature-capture-control {
  max-width: 400px;

  h3 {
    margin-top: 0;
    font-size: 1rem; }

  .button {
    margin: 0;
    width: 100%; }

  .deposit-message, .decline-message {
    padding-bottom: 1rem; } }
