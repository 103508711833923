@import '@payaca/styles/variables.sass';

.loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue-lighter;
  z-index: 100; }
