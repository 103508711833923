.subsidised-finance-information-overview .sm-view-label {
  display: none;
}

.subsidised-finance-information-overview .address-container {
  white-space: pre-wrap;
}

.subsidised-finance-information-overview section:not(:last-child) {
  padding-bottom: 2rem;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 2rem;
}

.subsidised-finance-information-overview section h3 {
  font-size: 1.1rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.subsidised-finance-information-overview
  .multiline-label-value
  .label-value-pair
  > * {
  white-space: pre-wrap;
  display: block;
}

.subsidised-finance-information-overview table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.subsidised-finance-information-overview table thead {
  background-color: #c4c4c4;
}

.subsidised-finance-information-overview table thead th,
.subsidised-finance-information-overview table tbody td {
  padding: 0.5rem 0.75rem;
  border: none;
  border-width: 0px;
  line-height: 1.2em;
}

.subsidised-finance-information-overview table tbody tr {
  background-color: #fff;
  cursor: default !important;
  box-shadow: none !important;
}

.subsidised-finance-information-overview table tbody tr:nth-child(even) {
  background-color: #ededea;
}

/* BUSINESS INFORMATION OVERVIEW */

.subsidised-finance-information-overview .business-information-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .business-information-overview
  .label-value-pair
  .label {
  color: #555;
}

.subsidised-finance-information-overview
  .business-information-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subsidised-finance-information-overview
  .business-information-overview
  .basic-information-container
  > *:not(:last-child),
.subsidised-finance-information-overview
  .business-information-overview
  .addresses-container
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subsidised-finance-information-overview
  .business-information-overview
  .company-contacts-container
  > *:not(:last-child),
.subsidised-finance-information-overview
  .business-information-overview
  .directors-container
  > *:not(:last-child),
.subsidised-finance-information-overview
  .business-information-overview
  .associated-businesses-container
  > *:not(:last-child) {
  margin-bottom: 0.2rem;
}

/* END BUSINESS INFORMATION OVERVIEW */

/* FINANCIAL INFORMATION OVERVIEW */

.subsidised-finance-information-overview .financial-information-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .financial-information-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subsidised-finance-information-overview
  .financial-information-overview
  .products-container
  > *:not(:last-child) {
  margin-bottom: 0.2rem;
}

/* END FINANCIAL INFORMATION OVERVIEW */

/* REGULATORY REQUIREMENTS OVERVIEW */

.subsidised-finance-information-overview .regulatory-requirements-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .regulatory-requirements-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

/* END REGULATORY REQUIREMENTS OVERVIEW */

/* ADDITIONAL INFORMATION OVERVIEW */

.subsidised-finance-information-overview .additional-information-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .additional-information-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

/* END ADDITIONAL INFORMATION OVERVIEW */

/* AUTHORITY TO SEARCH OVERVIEW */

.subsidised-finance-information-overview .authority-to-search-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .authority-to-search-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subsidised-finance-information-overview
  .authority-to-search-overview
  .subsidised-finance-data-protection-declaration
  > * {
  margin: 0;
}

.subsidised-finance-information-overview
  .authority-to-search-overview
  .subsidised-finance-data-protection-declaration {
  margin-bottom: 0.2rem;
}

/* END AUTHORITY TO SEARCH OVERVIEW */

/* SUPPORTING DOCUMENTS OVERVIEW */

.subsidised-finance-information-overview .supporting-documents-overview {
  font-size: 0.9rem;
}

.subsidised-finance-information-overview
  .supporting-documents-overview
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

/* END SUPPORTING DOCUMENTS OVERVIEW */

/* START SMALL VIEW */

.subsidised-finance-information-overview.sm-view .sm-view-label {
  display: inline-block;
  margin-right: 0.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: #555;
}

.subsidised-finance-information-overview.sm-view
  .business-information-overview
  > .flex-container {
  flex-direction: column;
}

.subsidised-finance-information-overview.sm-view
  .business-information-overview
  > .flex-container
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.subsidised-finance-information-overview.sm-view table thead {
  display: none;
}

.subsidised-finance-information-overview.sm-view table tr {
  display: block;
  padding: 0.5rem;
  border-top: 1px solid #c4c4c4;
  background-color: whitesmoke !important;
}

.subsidised-finance-information-overview.sm-view table tr:last-child {
  border-bottom: 1px solid #c4c4c4;
}

.subsidised-finance-information-overview.sm-view table tr td {
  display: block;
  padding: 0;
  text-align: left !important;
}

.subsidised-finance-information-overview.sm-view .address-container {
  white-space: normal;
}

.subsidised-finance-information-overview.sm-view .address-container > div {
  display: inline-block;
}

/* END SMALL VIEW */
