.sort-direction-control {
  display: flex;
  flex-direction: column;

  > * {
    cursor: pointer; }

  .active {
    opacity: 0.3;
    pointer-events: none; } }
