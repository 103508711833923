@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.job-page {
  background-color: $white;

  &.error {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: $blue-lighter;
    color: #000;

    .content-panel {
      display: flex;
      flex-direction: column;
      align-items: center; } }

  .page-inner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;

    .content-panel div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 300px;
        padding-bottom: 1rem; }
      p {
        margin-bottom: 0; } } }

  .job-viewer-header .job-overview-cta .payment-method-icon-container {
    display: none; }

  .job-overview-inner-wrapper {
    padding: 2rem 1.5rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;

    .job-overview-main {
      padding: 2rem 2.5rem;
      width: 100%;
      background: $white;
      @include box-shadow(); }

    .job-overview-status {
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: flex-end; } }

  .job-overview-wrapper {
    display: flex;
    flex-direction: row;
    margin: 105px 25px 30px 25px;
    background-color: $blue-lighter;
    justify-content: space-between;
    @include border-radius();

    .job-overview-side-wrapper {
      position: relative;

      .job-overview-side {
        position: sticky;
        right: 0;
        top: 130px;
        width: 250px;
        height: 80vh;
        margin-right: 1.5rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $blue-dark;
        @include border-radius(); } }

    .job-attachments {
      padding: 1rem 1.8rem; }

    .job-signature-capture-control-container {
      display: flex;
      flex-direction: column;
      align-self: flex-end; }

    .collapsible-panel {
      margin-bottom: 1rem; }

    .collapsible-panel-body {
      display: flex;
      justify-content: center; }

    .collapsible-panel-body > * {
      flex-grow: 1; }

    /* Standard and finance payment panels */
    .standard-and-finance-panels-wrapper {
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: flex-end; }

    .cta-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .job-overview-cta .button {
        min-width: 400px; }

      & > *:not(:last-child) {
        margin-bottom: 1rem; } }


    .finance-options-overlay-trigger {
      width: 100%;
      max-width: 500px; }

    /* END Standard and finance payment panels */

    /* Sat note */
    .satisfaction-note-signature-capture-control {
      max-width: 400px;
      .button {
        width: 100%; } }
    /* END Sat note */ } }

.bespoke-finance-breakdown-container.small-view .job-overview-cta,
.bespoke-finance-breakdown-container.small-view button {
  width: 100%; }


/* Bespoke finance breakdown */
.bespoke-finance-breakdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
/* END Bespoke finance breakdown */

.apply-bespoke-finance {
  text-align: end; }

.job-payment-feedback-modal {
  .title-container {
    padding-bottom: 0; }
  .body-content {
    text-align: center;
    p {
      margin: 0; } } }

@media (max-width: 1100px) {
  .job-page .job-overview-wrapper .job-overview-side-wrapper .job-overview-side {
    width: 210px; } }

@media (max-width: 800px) {
  .job-page {
    .job-overview-wrapper {
      flex-direction: column;
      background: $white;
      margin: 75px 10px 10px 10px;

      .cta-container .job-overview-cta .button {
        min-width: auto; }

      .job-overview-inner-wrapper {
        margin: 0;
        padding: 0.5rem 1rem;

        .job-overview-info {
          @include border-bottom();
          padding-top: 1.3rem; }

        .job-overview-main {
          box-shadow: none;
          padding: 2rem 0rem 1rem; } }

      .job-overview-side-wrapper {
        padding: 0 1.5rem;

        .job-overview-side {
          width: 100%;
          margin: 0;
          height: auto; } } } } }

@media (max-width: 650px) {
  .job-attachments {
    padding: 0;
    text-align: center;
    .job-attachments-wrapper {
      justify-content: center; } } }
