.job-overview-preview-page {
  margin-bottom: 3rem;

  .job-overview-outer {
    padding: 1rem 1rem 0 1rem; }

  .job-overview-header {
    margin-bottom: 1rem; }

  .finance-options-overlay.disabled {
    position: relative; }

  .standard-and-finance-panels-wrapper {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-end; }

  .standard-and-finance-panels-wrapper {
    .collapsible-panel:not(.open) {
      margin-bottom: 1rem;
      border: 3px solid #36577d;
      .collapsible-panel-header {
        background-color: #36577d;
        color: #fff; } } }

  .finance-options-overlay-trigger-container {
    display: flex;
    justify-content: flex-end; } }
