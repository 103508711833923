.subscription-pricing-information {
  margin-top: 42px;
}

.subscription-pricing-information * {
  box-sizing: border-box;
}

.subscription-pricing-information a {
  text-decoration-line: none;
}

.subscription-pricing-information .button {
  display: inline-flex;
}

.subscription-pricing-information ul.pricing__options {
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -3.5px;
  margin-left: -3.5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}
.subscription-pricing-information ul.pricing__options > li.pricing__option {
  position: relative;
  width: 100%;
  padding-right: 3.5px;
  padding-left: 3.5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  padding-top: 28px;
  padding-bottom: 28px;
  max-width: 18rem;
  border-bottom: 2px solid #ededea;
}
.subscription-pricing-information
  ul.pricing__options
  > li.pricing__option:last-child {
  border-bottom: none;
}
@media (min-width: 576px) {
  .subscription-pricing-information ul.pricing__options > li.pricing__option {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    border-bottom: none;
    max-width: auto;
    padding-top: 18.6666666667px;
    padding-bottom: 18.6666666667px;
    border-left: 2px solid #ededea;
  }
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:nth-child(odd) {
    border-left: none;
  }
}
@media (min-width: 992px) {
  .subscription-pricing-information ul.pricing__options > li.pricing__option {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    border-left: 2px solid #ededea;
    margin-top: 18.6666666667px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:nth-child(odd) {
    border-left: 2px solid #ededea;
  }
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:first-child,
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:nth-child(4n + 1) {
    border-left: none;
  }
}
@media (max-width: 575.98px) {
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:first-child {
    padding-top: 0;
  }
  .subscription-pricing-information
    ul.pricing__options
    > li.pricing__option:last-child {
    padding-bottom: 0;
  }
}
.subscription-pricing-information ul.pricing__options b,
.subscription-pricing-information ul.pricing__options h4,
.subscription-pricing-information ul.pricing__options h5 {
  display: block;
  margin: 0 auto;
}
.subscription-pricing-information ul.pricing__options h4 {
  font-size: 1.3rem;
}
.subscription-pricing-information ul.pricing__options b,
.subscription-pricing-information ul.pricing__options h5 {
  font-size: 1.1rem;
}
.subscription-pricing-information ul.pricing__options b {
  font-family: Poppins, sans-serif;
  font-weight: 700;
}
.subscription-pricing-information ul.pricing__options b,
.subscription-pricing-information ul.pricing__options h5 {
  margin-top: 7px;
  margin-bottom: 7px;
}
.subscription-pricing-information ul.pricing__options a.btn {
  margin: 14px auto 0 auto;
}

.payaca-plans {
  text-align: center;
  margin-bottom: 20px;
}

.payaca-plan {
  width: 25%;
  background: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
}

.payaca-plan.selected {
  background-color: #ffe9aa;
}

.payaca-plans .payaca-plan:nth-child(1),
.payaca-plans .payaca-plan:nth-child(3),
.payaca-plans .payaca-plan:nth-child(4) {
  border-top: 2px solid #c4cbd3;
  border-bottom: 2px solid #c4cbd3;
}

.payaca-plans .payaca-plan:nth-child(3),
.payaca-plans .payaca-plan:nth-child(4) {
  border-right: 2px solid #c4cbd3;
}

.payaca-plans .payaca-plan:nth-child(2) {
  border: 2px solid #ffca2d;
}

.payaca-plans .payaca-plan:nth-child(1) {
  border-left: 2px solid #c4cbd3;
}

.payaca-plan .plan-title {
  font-size: 25px;
  margin-bottom: 20px;
}

.payaca-plan .main-plan-price {
  font-size: 15px;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.payaca-plan .main-plan-price .plus-vat {
  font-size: 15px;
}

.payaca-plan .main-plan-price .pricing-cost-amount {
  font-size: 30px;
  margin-bottom: 10px;
}

.payaca-plan-most-popular-wrapper {
  width: 100%;
  text-align: center;
}

.payaca-plan .contact-us {
  margin-top: 20px;
  line-height: 23px;
}

.payaca-plan-most-popular {
  width: 25%;
  background: #ffca2d;
  text-align: center;
  margin-left: 25%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
}

.subscription-pricing-information .selected-plan-content {
  margin: 30px 0px;
}

@media (max-width: 800px) {
  .payaca-plan {
    width: 100%;
    display: flex;
    height: auto !important;
    padding: 10px;
  }

  .payaca-plans .payaca-plan:nth-child(1) {
    border-bottom: 0px solid #c4cbd3;
  }

  .payaca-plans .payaca-plan:nth-child(3),
  .payaca-plans .payaca-plan:nth-child(4) {
    border-top: 0px solid #c4cbd3;
  }

  .payaca-plans .payaca-plan:nth-child(1),
  .payaca-plans .payaca-plan:nth-child(3),
  .payaca-plans .payaca-plan:nth-child(4) {
    border-left: 2px solid #c4cbd3;
    border-right: 2px solid #c4cbd3;
  }

  .payaca-plan-most-popular-wrapper {
    display: none;
  }
}

@media (min-width: 1200px) {
  .subscription-pricing-information {
    max-width: 1140px;
  }
}
