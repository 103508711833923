@import '@payaca/styles/variables.sass';

.switch {
  .MuiSwitch-switchBase + .MuiSwitch-track, .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1; }

  .MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: $grey-mid; }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: $col-primary-dark; }

  .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 0.12; } }




