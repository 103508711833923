.decline-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  .button {
    min-width: 195px;
    &:not(:last-child) {
      margin-bottom: 1rem; } } }
