@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.signature-capture {
  canvas {
    width: 100%;
    height: 200px;
    border: 1px solid $blue-light;
    @include border-radius(); }

  .clear-signature {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: $col-secondary; }

  .sign-here-prompt {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
    opacity: 0;
    transition: opacity 1s; }

  &.display-sign-here-prompt .sign-here-prompt {
    opacity: 1; } }
