.job-payment-control {
  .payment-summary {
    text-align: center; }
  & > *:not(:last-child) {
    margin-bottom: 1rem; }

  .loader-overlay {
    background: rgba(255, 255, 255, 0.5); }

  .payment-method-selection-container {
    & > *:not(:last-child) {
      margin-bottom: 1rem; }

    .payment-method-icon-container {
      display: inline-block; }

    .card-payment-panel-header {
      flex-wrap: wrap;
      & > span {
        line-height: 1.2rem; } }

    .collapsible-panel-body > div > p {
      margin-top: 0; }

    &.single-payment-method {
      .collapsible-panel-body-container {
        height: auto !important; }
      .collapsible-panel-header {
        cursor: default; } }

    .stripe-loading-wrapper {
      display: flex;
      justify-content: center; } } }

@media (max-width: 600px) {
  .job-payment-control .payment-method-selection-container .card-payment-panel-header .payment-method-icon-container img {
    max-width: 30px; } }

