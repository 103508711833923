.checkbox-field {
  display: flex;
  align-items: center;
}

.checkbox-field > *:not(:last-child) {
  margin-right: 1rem;
}

.checkbox-field .checkbox {
  margin: -0.5em;
}
