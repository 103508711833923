@import '@payaca/styles/mixins.sass';
.satisfaction-note-declaration {
  @include border-radius();
  h3, p, ol {
    margin: 0; }
  > *:not(:last-child) {
    margin-bottom: 1rem; }
  ol {
    padding-inline-start: 1.5rem;

    > *:not(:last-child) {
      margin-bottom: 0.5rem; } } }
