@import '@payaca/styles/variables.sass';

.formatted-number-field {
  .input-wrapper {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem; } }

  .field-label {
    margin-bottom: 2px; } }
