@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import 'variables.sass';
@import 'mixins.sass';
@import 'tablestyles.sass';

html {
  -ms-overflow-style: -ms-autohiding-scrollbar; }

* {
  box-sizing: border-box; }

body {
  color: $blue-darker; }

body, html, input, textarea {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em; }

input, textarea {
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-family: Poppins, sans-serif;
  font-weight: 600; }

h1, h2, h3, h4, h5, h6, p {
  margin: 1rem 0; }

.bold-weight {
  font-family: Poppins, sans-serif;
  font-weight: 700; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 20px; }

hr {
  border: none;
  border-top: 1px solid rgba($blue-dark, 0.2); }

a, .link {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  color: $blue-dark;

  &:active, &:hover {
    color: $blue-darker; } }

ul {
  padding-left: 25px; }

strong {
  font-weight: 600; }

input {
  border: none;

  &:focus {
    outline: none; } }

.flex-container {
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 1em; }

  &.flex-contiguous > *:not(:last-child) {
    margin-right: 0; }

  &.flex-center {
    align-items: center; } }

.flex-grow {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.background_secondary-blue {
  background-color: $blue-darker;
  color: $yellow; }





