@import '../../styles/variables.sass';
@import '../../styles/mixins.sass';

.popup-menu {
  z-index: 2000;

  .spinner-container {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    align-items: center; }

  .menu-option.processing .option-name {
    visibility: hidden; }

  .MuiPaper-root {
    @include border-radius();
    @include box-shadow(); } }

