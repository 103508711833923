@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

body, html, input, textarea {
  font-size: 12px; }

.job-overview-page {
  table {
    width: 100%;
    border-spacing: 0px; }

  .label-value-pair {
    margin-bottom: 0px; }

  .job-overview-header {
    margin-bottom: 2rem;
    & > *:not(:last-child) {
      margin-bottom: 0.5em; }

    .job-overview-job-information-container {
      .job-information-container .label-value-pair:not(:last-child) {
        margin-bottom: 0px; }

      .to-information-container > *:not(:last-child) {
        margin-bottom: 0px; } } }

  .job-overview > *:not(:last-child) {
    margin-bottom: 1em; }

  .job-overview-item {
    padding: 0.5em;

    .images-wrapper > img {
      max-height: 100px; } }

  .job-overview-page-inner {
    padding: 1rem;
    padding-bottom: 2rem; } }


@media (max-width: 650px) {
  .job-attachments {
    padding: 0;
    text-align: center;
    .job-attachments-wrapper {
      justify-content: center; } } }

@media print {
  html {
    -webkit-print-color-adjust: exact; }

  .job-overview-page-inner {
    padding: 0; }

  .job-overview-page {
    footer {
      position: fixed;
      bottom: 0;
      background: white; } }

  .footer-placeholder-small {
    height: 105px; }
  .footer-placeholder-medium {
    height: 120px; }
  .footer-placeholder-large {
    height: 135px; } }
