/* FINANCE OPTIONS OVERLAY */

.finance-options-overlay {
  position: fixed;
  left: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  transition: left 500ms cubic-bezier(0.155, 0.62, 0.265, 1);
  -webkit-box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.finance-options-overlay.active {
  left: 0;
}

.finance-options-overlay:not(.active) .finance-options-overlay-action-bar {
  cursor: pointer;
}
.finance-options-explainer-container {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

/* END FINANCE OPTIONS OVERLAY */

/* FINANCE OPTIONS OVRLAY ACTION BAR */

.finance-options-overlay-action-bar {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #263e59;
}

.finance-options-overlay-action-bar .action-bar-title-and-description {
  flex-grow: 1;
  color: #ffca2d;
}

.finance-options-overlay-action-bar .action-bar-title {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 1rem;
}

/* Customise from standard buttons */
.finance-options-overlay-action-bar .action-container .button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.finance-options-overlay-action-bar .dismiss-action {
  color: #fabb00;
  font-size: 2rem;
  cursor: pointer;
}

.finance-options-overlay-action-bar .dismiss-action .fa-times {
  font-size: 1.3rem;
}

.finance-options-overlay-action-bar .action-container .close-action svg {
  margin-left: 0.5em;
}

.finance-options-overlay-action-bar .action-bar-legal {
  display: block;
  font-size: 0.7rem;
  margin-top: 0.5rem;
  color: #fff;
}

@media (max-width: 850px) {
  .finance-options-overlay-action-bar .action-bar-title-and-description > * {
    display: block;
  }

  .finance-options-overlay-action-bar .action-bar-title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 0.2rem;
  }

  .finance-options-overlay-action-bar .action-bar-description {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .finance-options-overlay-action-bar .action-bar-legal {
    font-size: 0.6rem;
  }

  .finance-options-overlay-action-bar .action-container {
    text-align: right;
    margin-top: 0.5rem;
  }

  .finance-options-overlay-action-bar .action-container .button {
    margin: 0.4rem;
  }
}

/* END FINANCE OPTIONS OVERLAY ACTION BAR */

/* FINANCE OPTIONS EXPLAINER */

.finance-options-explainer-wrapper {
  position: relative;
  width: 100%;
}

.finance-options-explainer,
.simple-finance-explainer,
.selina-finance-explainer {
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transition: all 500ms cubic-bezier(0.155, 0.62, 0.265, 1);
  -moz-transition: all 500ms cubic-bezier(0.155, 0.62, 0.265, 1);
  -o-transition: all 500ms cubic-bezier(0.155, 0.62, 0.265, 1);
  transition: all 500ms cubic-bezier(0.155, 0.62, 0.265, 1);
}

.finance-options-explainer {
  left: -100%;
  right: 100%;
}

.simple-finance-explainer,
.selina-finance-explainer {
  left: 100%;
  right: -100%;
}

.finance-options-explainer.active,
.simple-finance-explainer.active,
.selina-finance-explainer.active {
  left: 0;
  right: 0;
}

.finance-options-explainer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.finance-options-explainer-content .buttons-container {
  max-width: 600px;
  width: 600px;
}

.finance-options-explainer-content .buttons-container .button {
  flex-basis: 50%;
  min-height: 4rem;
  padding: 3rem 1.5rem;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.finance-options-explainer-content .buttons-container .tooltip-icon {
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
}

.finance-options-explainer-content .buttons-container .key-figure {
  margin: 0;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.simple-finance-explainer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.simple-finance-explainer-content > *:not(:last-child) {
  margin-bottom: 2rem;
}

.simple-finance-explainer-content .content-panel {
  padding: 2rem;
  width: 100%;
  max-width: 900px;
  border: none;
  border-radius: 0.36rem;
}

.simple-finance-explainer-content .simple-finance-explainer-title {
  margin-top: 0;
  text-align: center;
}

.simple-finance-explainer-content hr {
  width: 100%;
  max-width: 500px;
  border: none;
  border-bottom: 2px solid #fab62d;
}

.simple-finance-explainer-content
  .simple-finance-explainer-steps-outer-container {
  position: relative;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
}

.simple-finance-explainer-content .simple-finance-explainer-steps-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.simple-finance-explainer-content
  .simple-finance-explainer-steps-container
  > .finance-options-explainer-step {
  width: 150px;
  text-align: center;
}

.simple-finance-explainer-content
  .simple-finance-explainer-steps-container
  > .fa-arrow-right {
  align-self: center;
  font-size: 1.5rem;
  color: #ccc;
  width: 50px;
}

.simple-finance-explainer-content .button {
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  margin: auto;
  position: relative;
}

.finance-options-explainer-wrapper .representative-example-content p {
  margin: 0;
}

.finance-options-explainer-wrapper
  .representative-example-content
  p:not(:last-child) {
  margin-bottom: 0.5rem;
}

.selina-finance-explainer-content .content-panel {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
  color: #263e59;
}

.selina-finance-explainer-content .cobrand-logos-container {
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
}

.selina-finance-explainer-content .cobrand-logos-container img {
  width: 150px;
}

.selina-finance-explainer-content h1 {
  color: #fff;
  text-align: center;
  font-size: 3rem;
}

.selina-finance-explainer-content .introduction {
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  margin: 0;
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .finance-options-explainer-content h1 {
    color: #000;
  }
  .simple-finance-explainer-content .simple-finance-explainer-steps-container {
    display: block;
  }

  .simple-finance-explainer-content
    .simple-finance-explainer-steps-container
    > .finance-options-explainer-step {
    width: 100%;
  }

  .simple-finance-explainer-content
    .simple-finance-explainer-steps-container
    > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .simple-finance-explainer-content
    .simple-finance-explainer-steps-container
    > .fa-arrow-right {
    display: none;
  }

  .simple-finance-explainer-content
    .simple-finance-explainer-steps-outer-container {
    padding: 1rem 0;
  }

  .finance-options-explainer-wrapper .content-panel.box-shadow {
    box-shadow: none;
  }

  .finance-options-explainer-content .buttons-container {
    width: 100%;
  }

  .selina-finance-explainer-content h1 {
    font-size: 2.2rem;
    color: #263e59;
  }

  .selina-finance-explainer-content .introduction {
    color: #263e59;
  }

  .selina-finance-explainer-content .cobrand-logos-container {
    position: absolute;
    margin: 0 auto;
    margin-top: -6rem;
    left: 0;
    right: 0;
  }
  .selina-finance-explainer-content .selina-loan-calculator {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .selina-finance-explainer-content h1 {
    font-size: 1.4rem;
    color: #263e59;
  }

  .finance-options-explainer-content .buttons-container {
    flex-wrap: wrap;
  }

  .finance-options-explainer-content .buttons-container .button {
    flex-basis: 100%;
    min-height: 4rem;
    padding: 2rem;
    font-size: 1.2rem;
    line-height: 1.2em;
  }

  .finance-options-explainer-content
    .buttons-container
    .button:not(:last-child) {
    margin: 0;
    margin-bottom: 1rem;
  }

  .selina-finance-explainer-content .cobrand-logos-container {
    margin-top: -4rem;
  }

  .selina-finance-explainer-content .cobrand-logos-container img {
    width: 90px;
  }

  .selina-finance-explainer-content .introduction {
    margin-bottom: 2rem;
  }
}

/* END FINANCE OPTIONS EXPLAINER */

/* FINANCE OPTIONS EXPLAINER STEP */

.finance-options-explainer-step > * {
  display: block;
}

.finance-options-explainer-step .image-container {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 1.5rem;
}

.finance-options-explainer-step .image-container img {
  display: block;
  max-width: 80px;
  max-height: 100%;
  flex: 0 0 auto;
  background-color: #fff;
  padding: 0.5rem;
}

.finance-options-explainer-step span {
  font-size: 1rem;
}

@media (max-width: 800px) {
  .finance-options-explainer-step {
    display: flex;
    align-items: center;
  }

  .finance-options-explainer-step .image-container {
    flex-shrink: 1;
    min-width: 70px;
    width: 70px;
    height: 70px;
    margin: 0;
    margin-right: 1rem;
  }

  .finance-options-explainer-step .image-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .finance-options-explainer-step span {
    text-align: left;
  }
}

@media (max-width: 600px) {
  .finance-options-explainer .finance-options-explainer-title {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .finance-options-explainer-step span {
    font-size: 1rem;
  }
}

/* END FINANCE OPTIONS EXPLAINER STEP */

/* FINANCE OPTION SCREEN */

.finance-option-screen .content-panel {
  border: none;
  border-radius: 0.35rem;
}

.finance-option-screen .background-image {
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
}

.finance-option-screen .background-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -99;
}

.finance-option-screen .back-button-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.finance-option-screen .footer-disclaimer {
  padding: 1rem;
  width: 100%;
  font-size: 0.8rem;
  color: #fff;
  background-color: #263e59;
}

.finance-option-screen .footer-disclaimer-content {
  opacity: 0.6;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.finance-option-screen .footer-disclaimer hr {
  height: 0;
  border: none;
  border-bottom: 1px solid #fff;
  margin: 1rem 0;
}

.finance-option-screen {
  height: 100%;
  overflow: hidden;
}

.finance-option-screen-outer {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.finance-option-screen .finance-option-screen-inner {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: stretch;
  justify-content: center;
}

.finance-option-screen .finance-option-screen-inner .content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  margin: 0 auto;
  max-width: 900px;
}

.finance-option-screen .finance-option-screen-inner .content-wrapper > div {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finance-option-screen .finance-option-screen-inner > *:not(:last-child) {
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  .finance-option-screen {
    overflow-y: auto;
  }

  .finance-option-screen .content-panel {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .finance-option-screen .background-image {
    width: 100%;
    height: 15rem;
    position: relative;
  }

  .finance-option-screen .background-image-overlay {
    position: absolute;
    width: 100%;
    height: 15rem;
  }

  .finance-option-screen .finance-option-screen-outer {
    height: auto;
  }

  .finance-option-screen .finance-option-screen-inner .content-wrapper {
    padding: 3rem 1rem 1rem 1rem;
    min-height: 0;
  }
  .finance-option-screen .finance-option-screen-inner > *:not(:last-child) {
    margin-bottom: 4rem;
  }
}

@media (max-width: 500px) {
  .finance-option-screen .finance-option-screen-inner .content-wrapper {
    padding: 2rem 1rem 1rem 1rem;
    min-height: 0;
  }
  .finance-option-screen .finance-option-screen-inner > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .finance-option-screen .background-image,
  .finance-option-screen .background-image-overlay {
    height: 10rem;
  }
}

/* END FINANCE OPTION SCREEN */
