@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-overview-finance-plans {
  width: 100%;

  .job-overview-finance-plan:not(:last-child) {
    margin-bottom: 0.8rem; }

  // JOB OVERVIEW FINANCE PLAN

  .job-overview-finance-plan {
    border: 2px solid $blue-light;
    @include border-radius();

    .job-overview-finance-plan-summary {
      padding: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      color: #263e59;
      cursor: pointer; }

    .interest-rate-apr {
      font-size: 1.3rem;
      font-weight: 600; }

    .job-overview-finance-plan-summary .agreement-duration-and-minimum-monthly-payment {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-direction: column; }

    .button-container {
      display: flex;
      justify-content: center; }
    .agreement-duration {
      justify-content: center;
      font-weight: bold;
      font-size: 1.2em; }
    .minimum-monthly-payment {
      font-size: 0.9em; }

    .job-overview-finance-breakdown-container {
      overflow: hidden;
      height: auto;
      max-height: 0px;
      .job-overview-finance-breakdown {
        padding: 0em 1rem 1rem; } }

    &.buy-now-pay-later .agreement-duration {
      font-size: 1em; }

    // SELECTED STATE

    &.selected {
      border: 2px solid $blue-darker;
      transition: border-radius 200ms ease-out;

      .agreement-duration {
        font-size: 1.1em; }

      &.buy-now-pay-later {
        .agreement-duration {
          font-size: 0.9em; } }

      .job-overview-finance-plan-summary {
        padding-bottom: 0em; }

      .minimum-monthly-payment {
        display: none; }

      .job-overview-finance-breakdown-container {
        max-height: 650px; } } } }


.job-overview-finance-plan.selected .job-overview-finance-plan-summary, .job-overview-finance-plan .job-overview-finance-plan-summary, .job-overview-finance-plan .job-overview-finance-breakdown-container, .job-overview-finance-plan.selected .job-overview-finance-breakdown-container {
  transition: all 200ms ease-out; }

