@import '../../styles/variables.sass';
.persisted-file-control {
  display: flex;
  align-items: center;
  line-height: 1em;
  box-sizing: content-box;

  * {
    box-sizing: inherit; }

  .fa-check {
    color: $green-dark;
    font-size: 0.8em; } }
