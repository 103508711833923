.text-area-field {
  .input-wrapper {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    > *:not(:last-child) {
      margin-right: 0.5rem; } }

  .field-label {
    margin-bottom: 2px; } }
