.account-subscription-information p,
.account-subscription-information h3 {
  margin: 0;
}

.account-subscription-information.loader-container {
  justify-content: center;
  width: 100%;
  display: flex;
}

.account-subscription-information > *:not(:last-child) {
  margin-bottom: 1rem;
}

.account-subscription-information .costs-container {
  font-size: 0.9rem;
  flex-basis: 320px;
}

.account-subscription-information .payment-method-container {
  font-size: 0.9rem;
}

.account-subscription-information
  .payment-method-container
  .update-payment-method-trigger {
  text-decoration: underline;
  cursor: pointer;
}

.account-subscription-information .product-name-container p {
  font-size: 0.9rem;
}

.account-subscription-information .product-name-container .flex-container {
  align-items: baseline;
}

.account-subscription-information
  .product-name-container
  .flex-container
  > *:not(:last-child) {
  margin-right: 0.5rem;
}

.account-subscription-information .product-name-container span {
  color: red;
  font-size: 0.9rem;
}

.account-subscription-information
  .product-name-container
  .restore-subscription-plan-trigger {
  text-decoration: underline;
  cursor: pointer;
  color: black;
  justify-self: flex-end;
}

.account-subscription-information .costs-container .label-value-pair {
  display: flex;
}

.account-subscription-information .label-value-pair .label {
  font-weight: normal;
  flex-grow: 1;
}

.account-subscription-information .label-value-pair .value {
  font-weight: bold;
}

.account-subscription-information
  .collapsible-panel
  .subscription-information-container {
  padding-top: 1rem;
}

.account-subscription-information .subscription-information-container {
  display: flex;
  flex-wrap: wrap;
}

.account-subscription-information
  .subscription-information-container
  > *:not(:last-child) {
  margin-right: 1rem;
}

.account-subscription-information
  .subscription-information-container
  .features-payment-method-container {
  flex-grow: 1;
}

.account-subscription-information
  .subscription-information-container
  .features-payment-method-container
  > *:not(:last-child) {
  margin-bottom: 1rem;
}

.account-subscription-information .subscription-bonuses-container {
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  background-color: #f5f5f5;
  border-radius: 0.35rem;
}

.account-subscription-information .subscription-bonuses-container ul {
  margin: 0.25rem;
}
