@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.modal {
    .MuiBackdrop-root {
        backdrop-filter: blur(1.5px); }

    .MuiDialog-paper {
        color: inherit;
        @include border-radius();
        background-color: $blue-lighter;
        overflow: visible; }

    .MuiDialogContent-root, .MuiDialogContent-root:first-child {
        padding: 0;
        margin: 0;
        overflow: visible;
        display: flex;
        flex-direction: column;
        overflow: hidden;



        > .body-content {
            flex-grow: 1;
            overflow-y: auto; }


        .actions-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 2rem;

            > *:not(:last-child) {
                margin-bottom: 1rem; }

            button:not(.button-style-anchor) {
                min-width: 280px; } }

        > * {
            padding: 1rem 2rem;

            &.actions-container {
                padding: 1rem 2rem; }

            &:first-child {
                padding-top: 2rem; }

            &:last-child {
                padding-bottom: 2rem; } } }



    .title-container {
        text-align: center;

        > h3 {
            margin: 0;
            line-height: 1em; } }

    .close-control {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $blue-darker;
        border: 3px solid $white;
        color: $white;
        font-size: 20px;
        @include center-content();
        cursor: pointer; } }
