@import '../../styles/mixins.sass';
@import '../../styles/variables.sass';
.persisted-drag-drop-file-control {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  .persisted-drag-drop-file-control-inner {
    @include border-radius();
    padding: 1rem;
    display: flex;
    align-items: center;
    line-height: 1em;
    box-sizing: content-box;
    background: $blue-lighter;
    flex-grow: 1;

    .fa-paperclip {
      font-size: 1.8rem;
      margin-right: 1rem; }

    .file-name-upload-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 190px;
      flex-grow: 1;
      & > div:first-child {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5rem;
        .file-name {
          font-size: 1rem; }
        .upload-percentage {
          color: rgba($blue-darker, 0.5); } }

      .upload-bar-wrapper {
        overflow: hidden;
        .upload-bar {
          width: 100%;
          height: 2px;
          background: $col-secondary-dark;

          .upload-bar-inner {
            width: 40px;
            height: 2px;
            background: $white;
            animation: uploading 1s linear infinite; } } } }


    .check-icon-wrapper {
      margin-left: 1rem;
      background: $col-secondary;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      &.uploading {
        opacity: 0.2; }
      .fa-check {
        font-size: 0.8rem;
        color: $white; } } } }
.remove-button {
  display: flex;
  justify-content: center;
  color: $red;
  margin-top: 0.5rem;
  cursor: pointer;
  align-items: center;
  .cross-icon-wrapper {
    margin-left: 0.5rem;
    .mini-loader {
      width: 0.7rem;
      height: 0.7rem; } } }

@keyframes uploading {
  0% {
    margin-left: -40px; }
  100% {
    margin-left: 100%; } }
