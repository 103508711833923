@import '@payaca/styles/variables.sass';

.satisfaction-note-signature-capture-control {
  & > *:not(:last-child) {}
  margin-bottom: 1rem;

  .button {
    margin: 0; }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 0.5rem; }

  .reject-satisfaction-note-trigger {
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline; }

  .loader-container {
    text-align: center;
    padding: 1rem 0; }

  .rejection-description {
    color: $red; } }
