@import '@payaca/styles/variables.sass';

.demo-quote-landing-page-wrapper {
  width: 100vw;
  min-height: 100vh;
  background: $blue-light;
  padding: 4rem 0;

  .demo-quote-landing-page-content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;

    .demo-quote-landing-page-header {
      margin-bottom: 2em;

      img:first-of-type {
        display: block;
        margin: 1em auto;
        width: 300px; } }

    form .form-item {
      margin: 2.5em 0;

      .option-container {
        margin: 1em 0; }

      &.generate-quote-cta {
        display: flex;
        justify-content: center; } } } }
