.job-overview-header-upper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1.5rem;
  .company-logo {
    display: flex;
    justify-content: center;
    align-items: center; }
  .company-details-wrapper {
    display: inline-block;
    flex-grow: 1;
    text-align: right;
    .company-name {
      font-weight: 600;
      font-size: 1.1rem; }
    .company-contact {
      margin: 0; }
    .company-contact span {
      display: block;
      line-height: 1.5rem; } } }
