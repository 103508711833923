@import '../../styles/variables.sass';
@import '../../styles/mixins.sass';

.tooltip-wrapper {
  margin-left: 0.5rem;
  cursor: pointer;
  width: 16px;
  height: 16px;

  .tooltip-icon {
    width: 16px;
    height: 16px;

    svg {
      transform: scaleY(-1); } } }

.MuiTooltip-tooltip {
  font-size: 1rem !important; }
