.subsidised-finance-information-overview-page {
  padding: 1rem;
  padding-bottom: 5rem; }

@media print {
  html {
    -webkit-print-color-adjust: exact; }

  .subsidised-finance-information-overview-page {
    padding: 0; } }

