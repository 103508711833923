@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-overview-finance-breakdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;

  .after-deferral-description {
    text-decoration: underline;
    padding-bottom: 0.5em; }

  .slider-wrapper {
    display: flex;
    justify-content: flex-end;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin-bottom: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    @include box-shadow();
    @include border-radius(); }

  .finance-breakdown {
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-value-pair {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.3rem;

      &:last-child .value {
        font-family: Poppins, sans-serif;
        font-weight: 700; } } }

  ul {
    padding: 0;
    font-size: 0.8em;
    li {
      display: flex;
      flex-direction: row;

      .icon-container {
        padding-right: 0.5em; } } }

  .button-container {
    padding-top: 1rem;
    &, .button {
      width: 100%; } } }


.job-overview-finance-breakdown, .job-overview-finance-breakdown input {
  color: $col-secondary-dark; }

