.row-selection-table-cell {
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
}

.row-selection-table-cell .checkbox .MuiCheckbox-root:not(.Mui-checked) {
  color: #dbe5f5;
}

.row-selection-table-cell .checkbox,
.row-selection-table-cell .checkbox {
  margin: -0.5rem;
  display: block;
}

.row-selection-table-cell .checkbox .Mui-disabled {
  opacity: 0.5;
}
