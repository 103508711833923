@import '@payaca/styles/mixins.sass';

.StripeElement {
  font-size: 16px !important;
  max-width: 600px;
  margin: 15px 0;
  padding: 0.4rem 0.7rem;
  @include border-bottom();
  .IdealBankElement, .PaymentRequestButton {
    padding: 0; } }

.StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease; }

.add-payment-card {
  width: 100%;
  display: flex;
  align-items: center;

  .add-payment-card-form {
    width: 100%; } }

.checkout {
  width: 100%;
  .button-container {
    text-align: right;
    .button {
      display: inline;
      margin: 0.5rem 0; } }
  & > p {
    font-size: 0.9rem; } }

.stripe-error {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px; }

.loader-wrapper {
  display: flex;
  justify-content: center;
  height: 139px;
  align-items: center; }
