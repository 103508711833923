@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-overview-business-details {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  flex-direction: row;
  @include border-top;

  .payment-section, & > .job-overview-notes {
    flex-grow: 1; }
  & > .job-overview-notes {
    width: 60%; }

  .payment-section {
    margin-right: 0.7rem; }
  .payment-section > div:first-child {
    margin-bottom: 0.7rem; }

  .payment-section > div, & > .job-overview-notes {
    @include container-background(); }

  &.small-view {
    flex-direction: column;
    .payment-section {
      margin-right: 0; } } }

.job-overview-business-details.small-view {
  & > .job-overview-notes {
    width: 100%; } }
