@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.job-attachments {
  p:first-child {
    margin: 0.25em 0; }

  .job-attachments-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > .job-attachment {
      margin-bottom: 0.5rem;
      text-align: center;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      position: relative;
      color: $col-secondary-dark;
      font-size: 0.7rem;
      @include container-background(0.4rem 0.4rem 0.8rem);

      &.add label {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer; }

      .file-wrapper {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        height: 110px;
        justify-content: space-around; }

      div {
        display: flex;

        &:not(.background-colour) {
          z-index: 2; } }

      &:not(:last-child) {
        margin-right: 0.5rem; } }

    .image-icon-container {
      width: 100px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1; } } }

.job-attachments .job-attachment img, .jobattachments .job-attachment svg {
  max-width: 100%;
  max-height: 70px; }

.job-attachments .job-attachment.download:hover, .job-attachments .job-attachment.add:hover {
  cursor: pointer; }

.job-attachments .job-attachment.download:hover .background-colour, .job-attachments .job-attachment.add:hover .background-colour {
  opacity: 1; }

.job-attachments .job-attachment {}
/* File name */
.file-name-wrapper {
  justify-content: center;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis; }
  &.editable span {
    padding-right: 19px; } } // allow for edit icon

input {
  &.file-label.edit {
    width: 100%;
    border-bottom: 1px solid $col-secondary;
    color: $col-secondary; }

  &.hidden {
    display: none; } }


.error {
  color: red; }

/* Icons */
.file-type-icon {
  font-size: 40px; }
.delete-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10; }
.plus-icon {
  color: $col-secondary;
  margin: 5px; }

.delete-icon, .edit-icon, .plus-icon {
  cursor: pointer;
  font-size: 25px; }
.edit-icon {
  position: absolute;
  bottom: 9px;
  right: 5px;
  font-size: 18px; }
