@import '../../styles/variables.sass';
@import '../../styles/mixins.sass';

.file-upload-field {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .file-upload-trigger {
    cursor: pointer;
    > *:not(:last-child) {
      margin-right: 0.5rem; } }

  .field-label {
    margin-bottom: 2px; }

  .input-wrapper {
    width: 100%;
    &.disabled .file-upload-trigger {
      cursor: default;
      opacity: 0.65; } }

  .accepted-file-types {
    margin-left: 0.5rem;
    color: rgba($blue-dark, 0.5); }

  .file-list-container ul {
    margin: 0;
    margin-top: 0.5rem;
    padding: 0;
    list-style: none;
    font-size: 0.8em;

    li:not(:last-child) {
      margin-bottom: 0.2rem; } }
  svg {
    margin-right: 1rem; }

  // drag and drop
  &.drag-and-drop {
    .button {
      font-weight: 500;
      width: 100%;
      &.upload-image {
        padding: 0 10px;
        width: auto;
        .button-content {
          display: flex;
          justify-content: center;
          align-items: center; }
        svg {
          margin-right: 0.5rem; } } }


    .drag-drop-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-direction: column;
      padding: 1rem;
      @include border-radius();
      background: $blue-lighter;
      border: 2px dashed $blue-darker;
      width: 100%;
      &.disabled {
        opacity: 0.6; } }

    .or-text {
      text-align: center;
      margin: 0.5rem 0;
      font-weight: 700;

      &.dragging {
        opacity: 0.6; } }

    svg {
      margin-right: 0; } } }

