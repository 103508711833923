@font-face {
  font-family: "payaca Icons";
  src: url("./payaca-icons.eot?0a0c4fb043cf93aeeb74907b6cba7c1e");
  src: url("./payaca-icons.eot?0a0c4fb043cf93aeeb74907b6cba7c1e#iefix")
      format("embedded-opentype"),
    url("./payaca-icons.woff?0a0c4fb043cf93aeeb74907b6cba7c1e") format("woff"),
    url("./payaca-icons.ttf?0a0c4fb043cf93aeeb74907b6cba7c1e")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

.pa-icon-facebook:before {
  content: "";
}

.pa-icon-twitter:before {
  content: "";
}

.pa-icon-linkedin:before {
  content: "";
}

#footer {
  min-height: 827px;
}
footer#footer > .container {
  padding-top: 70px;
  padding-bottom: 70px;
}
footer#footer > .container {
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
  margin-right: auto;
  margin-left: auto;
}
footer#footer .message a {
  color: #fab62d;
}
@media (max-width: 1200px) {
  footer#footer > .container {
    max-width: 90%;
  }
}
@media (min-width: 1200px) {
  footer#footer > .container {
    max-width: 1140px;
  }
}

footer#footer {
  position: relative;
  width: 100%;
  max-width: 100%;
  color: #fff;
}
footer#footer .disclaimer,
footer#footer .footer-col {
  margin-top: 18.6666666667px;
  margin-bottom: 18.6666666667px;
}
@media (min-width: 992px) {
  footer#footer .disclaimer,
  footer#footer .footer-col {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}
footer#footer .footer-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  footer#footer .footer-row {
    margin-top: 28px;
    margin-bottom: 28px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
footer#footer .footer-col {
  margin-left: 28px;
  margin-right: 28px;
}
footer#footer .footer-col:first-child {
  margin-left: 0;
}
footer#footer .footer-col:last-child {
  margin-right: 0;
}
footer#footer .footer-col--brand {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media (max-width: 991.98px) {
  footer#footer .footer-col--brand {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-right: 0;
  }
}

footer#footer .footer-logo img,
footer#footer .footer-logo svg {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 140px;
}
@media (min-width: 992px) {
  footer#footer .footer-logo img,
  footer#footer .footer-logo svg {
    max-width: 350px;
  }
}
footer#footer .message {
  margin: 0;
  color: #ffc232;
}
footer#footer .message * {
  margin: 0;
  font-size: 1rem;
}
footer#footer .disclaimer small {
  color: rgba(255, 255, 255, 0.6);
}
footer#footer .legals {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 992px) {
  footer#footer .legals {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
footer#footer .legals .copyright {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: 7px;
}
footer#footer .legals ul.legals-nav {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
@media (max-width: 991.98px) {
  footer#footer .legals .copyright {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 28px;
    margin-right: 0;
  }
  footer#footer .legals ul.legals-nav {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
footer#footer a.btn,
footer#footer h4 {
  margin: 0 0 18.6666666667px 0;
}
footer#footer ul.footer-nav,
footer#footer ul.legals-nav {
  padding-left: 0;
  list-style: none;
  font-size: 0.9090909091rem;
  margin: 0;
}
footer#footer ul.footer-nav a,
footer#footer ul.legals-nav a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration-line: none;
}
footer#footer ul.footer-nav a:active,
footer#footer ul.footer-nav a:focus,
footer#footer ul.footer-nav a:hover,
footer#footer ul.legals-nav a:active,
footer#footer ul.legals-nav a:focus,
footer#footer ul.legals-nav a:hover {
  color: #fab62d;
}
footer#footer ul.footer-nav li {
  margin: 9.3333333333px 0;
}
footer#footer ul.footer-nav li:first-child {
  margin-top: 0;
}
footer#footer ul.footer-nav li:last-child {
  margin-bottom: 0;
}
footer#footer ul.legals-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
footer#footer ul.legals-nav > li {
  margin: 0 0 0 1.3rem;
}
footer#footer ul.legals-nav > li:first-child {
  margin-left: 0;
}
footer#footer ul.legals-nav > li:last-child {
  margin-right: 0;
}
@media (max-width: 991.98px) {
  footer#footer ul.legals-nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  footer#footer ul.legals-nav > li {
    margin: 9.3333333333px 1.56rem 0 0;
  }
  footer#footer ul.legals-nav > li:first-child {
    margin-left: 0;
  }
  footer#footer ul.legals-nav > li:last-child {
    margin-right: 0;
  }
}
footer#footer ul.social-networks {
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 18.6666666667px 0 0 0;
  color: #ffc232;
}
footer#footer ul.social-networks > li {
  margin-top: 14px;
  margin-right: 9.3333333333px;
}
footer#footer ul.social-networks > li:last-child {
  margin-right: 0;
}
footer#footer ul.social-networks > li a {
  font-family: "payaca Icons";
  position: relative;
  text-decoration: none;
  font-size: 0;
  padding: 0;
  margin: 0;
  speak: normal;
  color: transparent;
  width: 2.2rem;
  height: 2.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: background-color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    -webkit-transform 580ms cubic-bezier(0.155, 0.62, 0.265, 1);
  transition: background-color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    -webkit-transform 580ms cubic-bezier(0.155, 0.62, 0.265, 1);
  transition: background-color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    transform 580ms cubic-bezier(0.155, 0.62, 0.265, 1);
  transition: background-color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    color 360ms cubic-bezier(0.155, 0.62, 0.265, 1),
    transform 580ms cubic-bezier(0.155, 0.62, 0.265, 1),
    -webkit-transform 580ms cubic-bezier(0.155, 0.62, 0.265, 1);
  color: #ffc232;
}
footer#footer ul.social-networks > li a:before {
  color: #ffc232;
  font-size: 1.0476190476rem;
}
footer#footer ul.social-networks > li a > span {
  font-size: 0;
  overflow: hidden;
  -ms-flex-preferred-size: 0;
  flex-basis: 0%;
  width: 0;
  height: 0;
  speak: normal;
  color: transparent;
}
.footer-app-badges img {
  max-width: 126.09px;
}
.footer-app-badges > a {
  display: block;
}

@media (max-width: 450px) {
  footer#footer .footer-col--trades {
    width: 100%;
  }

  footer#footer .footer-col--trades > div {
    max-width: 33%;
  }
}

.nav-menu-case-study > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 500px) {
  footer#footer .footer-col--trades {
    display: block;
  }

  footer#footer .footer-col--trades > div {
    width: 100%;
    margin-bottom: 30px;
  }
}
