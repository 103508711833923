@import '@payaca/styles/variables.sass';

@mixin border-bottom-white-20() {
  border-bottom: 1px solid rgba($white,0.2); }


.job-overview-timeline-container {
  position: relative;
  flex-grow: 1;
  color: $white;
  padding: 0 1.5rem 1.5rem;
  overflow-y: auto;

  .job-overview-timeline-events {
    position: relative;
    .present-events .job-overview-timeline-event .icon-wrapper {
      background: $col-primary-dark; }
    .past-events {
      opacity: 0.5;
      .job-overview-timeline-event .icon-wrapper {
        background: $white; } } }

  .present-events, .past-events {
    position: relative; }

  .next-step-title {
    margin: 0;
    padding: 2rem 0;
    @include border-bottom-white-20(); }

  // TIMELINE EVENT
  .job-overview-timeline-event {
    padding: 1rem 0;
    line-height: 1.2em;
    position: relative;
    @include border-bottom-white-20();

    .job-overview-timeline-event-summary {
      display: flex;
      align-items: center;
      .job-overview-timeline-body {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        .timestamp {
          font-size: 0.7rem;
          margin-bottom: 1rem; }
        .job-overview-timeline-event-summary-content {
          display: flex;
          flex-direction: column; }
        .job-overview-timeline-event-summary-content > span:not(.name) {
          padding-top: 1rem; }
        .name {
          font-weight: 600;
          display: block; } }

      .icon-wrapper {
        margin-right: 1rem;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        .icon {
          color: $col-secondary;
          font-size: 0.7rem; } }

      span span:not(.name) {
        font-size: 0.7rem; } }

    .lender-help-text {
      cursor: pointer;
      text-decoration: underline; } } }

/* LENDER MODAL */
.lender-help-modal .content-wrapper .information-detail a {
  color: $col-secondary-dark;
  text-decoration: underline; }
.lender-help-modal .MuiDialogContent-root {
  font-size: 14px; }

.lender-help-modal .content-wrapper .information-detail {
  padding-bottom: 0.5rem; }
.lender-help-modal .content-wrapper .information-detail > span:first-child {
  font-weight: 600;
  padding-right: 0.5rem; }
