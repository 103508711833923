@import '../../styles/variables.sass';
@import '../../styles/mixins.sass';

.file-upload-persist-remove-control {
  width: 100%;
  .file-list-container {
    font-size: 0.8em;
    ul {
      list-style: none;
      padding: 1rem 2rem; }

    li {
      display: flex;
      align-items: center;
      line-height: 1em;
      box-sizing: content-box; }

    li:not(:last-child), ul:not(:last-child) {
      margin-bottom: 0.2rem; }

    li {
      > *:not(:last-child), .persisted-file-control > *:not(:last-child) {
        margin-right: 0.5rem; } }

    .mini-loader {
      width: 0.5em;
      height: 0.5em; } }


  .remove-file-trigger {
    color: rgba($blue-dark, 0.5);
    text-transform: uppercase;
    cursor: pointer; }

  .modify-file-name-trigger {
    cursor: pointer; }

  .persist-file-name-trigger {
    text-transform: uppercase;
    cursor: pointer; }

  .file-list-container li .persisted-file-control .basic-field {
    input {
      font-size: 1em;
      line-height: 1em; }

    .input-wrapper {
      padding-bottom: 0; } }

  &.drag-and-drop {
    ul {
      padding: 1rem 0; } } }
