.job-overview-notes {
  white-space: pre-wrap;
  width: 100%; }

.job-overview-notes p {
  margin: 0;
  line-height: 1.4; }

.job-overview-notes p:first-child {
  margin-bottom: 0.25em; }
