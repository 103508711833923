@keyframes example {
  0% {
    margin-left: 1rem; }
  50% {
    margin-left: -1rem; }
  100% {
    margin-left: 1rem; } }


.sign-here-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  width: 120px;
  pointer-events: none;
  justify-content: center;
  color: #888;

  svg {
    font-size: 2rem; }

  .animated-icon-container {
    margin-top: 0.2rem;
    margin-left: -1rem;
    animation-name: example;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; } }
