@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.radio-button {

  .MuiRadio-root {
    color: $blue-darker; }

  .radio-wrapper {
    width: 16px;
    height: 16px;
    font-size: 8px;
    border: 2px solid $blue-darker;
    @include center-content();
    border-radius: 50%;
    color: $blue-darker; } }


