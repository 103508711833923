.date-field .input-wrapper {
  display: flex;
  align-items: center;
}

.date-field .input-wrapper > *:not(:last-child) {
  margin-right: 0.5rem;
}

.date-field .field-label {
  margin-bottom: 2px;
}

.date-field .MuiInputBase-input {
  padding: 0;
}
