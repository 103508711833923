@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.slider-wrapper {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;

  .slider-control {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 0;
    width: 100%;

    .slider-labels {
      display: flex;
      justify-content: space-between;
      font-weight: 500; }

    .MuiSlider-root {
      color: #fab62d; } }

  .input-control {
    display: flex;
    align-items: center;
    padding-left: 2rem;

    input {
      border-radius: 0.36rem;
      background: 0 0;
      background-color: $blue-lighter;
      transition: background-color 0.36s cubic-bezier(0.155, 0.62, 0.265, 1);
      font-size: 1.1rem;
      cursor: pointer;
      width: 6rem;
      padding: 0.5rem 0.65rem 0.5rem 1rem;

      &:hover {
        background-color: #fafafa; }
      &:focus {
        outline: 0;
        border-color: #fab62d; } }

    .input-control-label {
      font-size: 1.1rem;
      font-family: Poppins, sans-serif;
      font-weight: 700;
      display: block;
      padding-right: 0.5rem; } } }

.slider-input-and-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
