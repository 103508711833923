.job-overview-cta {
  text-align: right;

  .button {
    margin: 0;
    min-width: 195px;
    display: inline-block; }

  .payment-method-icon-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    & > img {
      width: 34px; } } }


@media (max-width: 800px) {
  .job-overview-cta .button {
    min-width: 140px; } }
