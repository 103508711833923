@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.job-overview-header-lower {
  display: flex;
  justify-content: space-between;
  background-color: $blue-lighter;
  padding: 1.5rem;
  @include border-radius();

  .job-information-container {
    text-align: right; }
  .to-information-container {
    .customer-name {
      font-weight: 600;
      font-size: 1.2rem;
      display: block; }
    .address span {
      display: block;
      line-height: 1.5rem; } } }
