@import '@payaca/styles/variables.sass';

.job-viewer-header {
  position: fixed;
  background-color: $blue-lighter;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  z-index: 100;
  top: 0;
  transition: top 300ms;
  overflow: hidden;
  left: 0;

  .job-title {
    padding: 0 1.5rem;
    font-size: 1.4rem; }
  .collapsed {
    top: -72px; }

  & > div {
    flex-grow: 1; }

  .job-viewer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 0;
    margin-left: 1rem; }

  .job-viewer-button.pdf-download {
    padding-right: 20px;
    width: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    .download-text {
      font-size: 14px;
      font-weight: 500;
      padding-left: 0.5rem; }
    button.button {
      height: 42px;
      border-radius: 21px;
      width: 42px;
      display: flex;
      justify-content: center; } }

  .job-viewer-header a {
    text-decoration: none !important;
    color: #000; }

  .job-viewer-header .job-viewer-button.accept-quote {
    background-color: rgb(216, 255, 221); } }

@media (max-width: 700px) {
  .job-viewer-header {
    .job-viewer-button.pdf-download {
      padding-right: 1.1rem;

      .download-text {
        display: none; }
      button.button {
        height: 30px;
        width: 30px;
        border-radius: 15px; } } } }
