.phone-number-field .react-tel-input .form-control {
  padding: 0px 0px 0px 45px;
  width: 100%;
  border: none;
}
.phone-number-field .react-tel-input .form-control:focus,
.phone-number-field .react-tel-input .selected-flag:focus:before,
.phone-number-field .react-tel-input .selected-flag.open:before,
.phone-number-field .react-tel-input .selected-flag:before {
  box-shadow: none;
  border: none;
}

.phone-number-field .react-tel-input .selected-flag {
  padding: 0;
}

.phone-number-field .input-wrapper {
  display: flex;
  align-items: center;
}

.phone-number-field .input-wrapper > *:not(:last-child) {
  margin-right: 0.5rem;
}

.phone-number-field .field-label {
  margin-bottom: 2px;
}
