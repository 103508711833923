@import '@payaca/styles/variables.sass';

.account-contact-details {
  p {
    margin: 0; }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem; }

  .label {
    margin-right: 0.3rem; }
  .label, .company-name {
    font-weight: 700;
    display: inline-block; }
  a {
    font-weight: 500;
    color: $col-secondary-dark;
    text-decoration: none; } }
