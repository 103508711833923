@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.tab {
  @include border-radius();
  font-family: inherit;
  padding: 0 16px;
  height: 30px;
  line-height: 1em;
  text-decoration: none;
  letter-spacing: 0.02rem;
  font-variant-ligatures: none;
  font-variant: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: relative;
  color: $blue-darker;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: $blue-lighter {
    color: rgba($col-secondary, 0.8); }

  // this ensures that the tab width doesn't change when is selected and text becomes bold
  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden; }


  &.active {
    // background-color: $blue-dark;
    // color: $white;
    background-color: $blue-light;
    color: $col-secondary;
    font-weight: 600; }

  &.disabled {
    background-color: rgba($blue-lighter, 0.5);
    color: rgba($col-secondary, 0.5);
    cursor: default; } }



.tabbed-view-container {
  position: relative;

  .tabs-wrapper {
    width: 100%;
    overflow-y: hidden;
    margin-bottom: 1rem;

    .tabs {
      display: flex;
      align-items: flex-center;
      margin-right: -0.5rem;
      margin-bottom: -0.5rem;
      flex-wrap: wrap;

      .tab {
        margin-bottom: 0.5rem;
        margin-right: 0.5rem; } } } }

//   .tab
//     padding: 0.4rem 1rem
//     color: #263e59
//     background: #fff2cc
//     border-top: 1px solid #fabb00
//     border-left: 1px solid #fabb00
//     height: 38px
//     min-width: 125px
//     text-align: center
//     white-space: nowrap
//     text-overflow: ellipsis
//     overflow: hidden

//     &:first-child
//       border-radius: 6px 0px 0px 0px
//       border-left: 1px solid #fabb00

//     &:last-child
//       border-radius: 0px 6px 0px 0px
//       border-right: 1px solid #fabb00

//     &:hover
//       cursor: pointer

//     &.active
//       background: #fff
//       border-top: 1px solid #c4c4c4
//       border-right: 1px solid #c4c4c4
//       border-left: 1px solid #c4c4c4
//       z-index: 10

//     &.disabled
//       color: #c4c4c4

//     &.active + .tab
//       border-left: none

// @media (max-width: 800px)
//   .tabs .tab
//     min-width: auto
//     text-overflow: inherit
//     overflow: visible

// .tab-content-container
//   position: relative
//   margin-top: 38px
//   top: -1px
//   width: 100%
