@import '@payaca/styles/variables.sass';

.sortable-column-header-table-cell .sortable-column-header-inner {
  display: flex;
  align-items: center;



  .sort-direction-control {
    margin-left: 0.75rem;
    color: $blue-darker;

    svg:first-child {
      margin-bottom: -0.4em; } } }
