@import '@payaca/styles/variables.sass';

.field-label {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.5em; }

  .description {
    font-size: 0.8em;
    color: rgba($blue-darker, 0.75); }

  .required-indicator {
    color: red; } }
