@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.input-wrapper.v2 {
  border: 1px solid transparent;
  min-height: 35px;
  padding: 0 1rem;
  background-color: #fff;
  @include box-shadow();
  @include border-radius-sm();
  transition: border-color 0.1s ease-in-out;

  &:focus-within {
    border-color: transparent; }

  input {
    line-height: 1em; }

  input, textarea {
    background-color: transparent;
    border: none;
    max-width: 100%;
    width: 100%;
    font-size: 1em;
    outline: none;
    padding: 0;
    margin: 0;

    &::placeholder {
      color: $blue-darker;
      opacity: 0.5; } }

  input:disabled {
    background-color: transparent; }

  &.disabled {
    pointer-events: none;
    color: rgba($blue-darker, 0.5); }

  &.input-wrapper-outsize {
    min-height: 50px;
    @include border-radius(); }

  &.input-wrapper-unstyled {
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    border: none; }

  &.input-col-primary {
    background-color: $col-primary; }

  &.input-col-secondary {
    background-color: $blue-lighter; }

  &.input-col-secondary:hover {
    background-color: $blue-light; }

  &.input-no-shadow {
    box-shadow: none; } }
