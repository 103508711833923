.selina-loan-calculator {
  width: 100%;
  max-width: 500px;
}

.selina-loan-calculator h2 {
  text-align: center;
  margin: 1rem 0 2rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

.selina-loan-calculator .maximum-credit-limit-information-container {
  text-align: center;
}

.selina-loan-calculator .maximum-credit-limit-information-container p {
  margin: 0;
}

.selina-loan-calculator
  .maximum-credit-limit-information-container
  .maximum-credit-limit {
  font-size: 1.5rem;
  font-weight: bold;
}

.selina-loan-calculator
  .maximum-credit-limit-information-container
  .icons-container
  svg {
  color: #8bc34a;
  font-size: 2rem;
}

.selina-loan-calculator
  .maximum-credit-limit-information-container
  .icons-container
  > *:not(:last-child) {
  margin-right: 0.5rem;
}

.selina-loan-calculator .form-contents > *:not(:last-child) {
  margin-bottom: 1rem;
}

.selina-loan-calculator .actions-container .button {
  width: 100%;
  padding: 1rem;
}

.selina-loan-calculator .slider-control {
  margin-top: 0;
  align-items: center;
}

.selina-loan-calculator .slider-control .slider-control-label:last-child {
  padding: 0;
  padding-left: 1rem;
}

.selina-loan-calculator .slider-control .slider-control-label:first-child {
  padding: 0;
  padding-right: 1rem;
}

.selina-loan-calculator .slider-input-and-description {
  font-weight: bold;
}

/* SMALL VIEW */

.selina-loan-calculator.sm-view .house-graphic {
  max-width: 100px;
}

.selina-loan-calculator.sm-view .introduction h2 {
  font-size: 1.2rem;
}
.selina-loan-calculator.sm-view .introduction p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.selina-loan-calculator.sm-view .form-contents > .flex-container {
  display: block;
}

.selina-loan-calculator.sm-view
  .form-contents
  > .flex-container
  > *:not(:last-child) {
  margin: 0;
  margin-bottom: 1rem;
}
/* END SMALL VIEW */
