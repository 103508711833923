.bacs-payment-control {
  .label-value-pair {
    line-height: 1.2rem;
    .label {
      min-width: 150px;
      display: inline-block; } }

  .button-container {
    margin-top: 1rem;
    text-align: right;

    .button {
      margin: 0;
      display: inline; } }

  .basic-field {
    width: 150px;
    margin-top: 0.5rem;
    .input-wrapper {
      padding: 1rem; } }

  .amount-due-exceeding-message {
    font-size: 0.9rem;
    padding-top: 0.5rem; }

  .input-and-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between; } }
