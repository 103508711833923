.radio-group-field .option-container {
  display: flex;
  align-items: center;
}

.radio-group-field .radio-button {
  margin: -10px;
}

.radio-group-field .radio-button-container {
  margin-right: 0.5rem;
}

.radio-group-field .options-container > *:not(:last-child) {
  margin-bottom: 0.3rem;
}

.radio-group-field .field-label {
  margin-bottom: 0.3rem;
}
