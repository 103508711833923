@import '@payaca/styles/variables';
@import '@payaca/styles/mixins';
.included-feature-table {
  padding: 0 2rem 1rem;
  @include border-radius();

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      padding: 1rem;
      background: $blue-light;
      position: sticky;
      top: 0;
      border-radius: 10px 0 0 10px;
      border-bottom: 1px solid rgba($col-secondary-dark, 0.2); }

    tbody td {
      border-bottom: 1px solid rgba($col-secondary-dark, 0.2); } }

  .included-feature-title {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    text-align: left; }

  .plan-feature-included {
    width: 135px;
    text-align: center;
    padding: 10px;

    .check-icon-wrapper {
      color: #fff;
      background: $col-secondary;
      border-radius: 10px;
      height: 20px;
      width: 20px;
      margin: auto;
      font-size: 10px; } }

  .plan-feature-title {
    width: calc(100% - (5 * 75px));
    padding: 1.5rem 1rem 1.5rem 0; }

  .plan-title {
    font-weight: 700;
    text-align: center;
    padding: 1.5rem 0;
    position: relative; }

  .plan-feature-section {
    padding: 1.5rem 1rem 1.5rem 0;
    font-weight: 600;
    font-size: 1.1rem; } }


.unlimited-symbol {
  display: none; }

@media (max-width: 1100px) {

  .included-feature-table .plan-title {
    height: 100px;
    padding-bottom: 1rem; }


  .included-feature-table .plan-title span {
    position: absolute;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    vertical-align: bottom;
    width: 90px;
    display: flex;
    font-size: 13px;
    left: 30%;
    bottom: 40px; }


  .plan-feature-included {
    width: 100px; } }


@media (max-width: 800px) {
  .plan-feature-included {
    width: 40px; }


  .included-feature-table .plan-feature-divider {
    width: 10px; }


  .included-feature-table table {
    font-size: 13px; }


  .payaca-plan .plan-title {
    margin-bottom: 10px; }


  .unlimited-word {
    display: none; }


  .unlimited-symbol {
    display: block;
    font-size: 22px; } }


@media (max-width: 450px) {
  .plan-feature-included {
    padding: 4px !important; } }


@media (max-width: 350px) {
  .plan-feature-included {
    padding: 2px !important; } }

