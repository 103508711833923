@import '@payaca/styles/variables.sass';

.feedback-message {
    margin: 0;
    font-size: 0.8em;
    line-height: 1.5em;

    .icon-container {
        width: 1em;
        display: flex;
        align-items: center;
        justify-content: center; }

    &.feedback-level-error {
        color: $red; }

    &.feedback-level-success {
        color: $green-dark; } }
