@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.collapsible-panel {
  .collapsible-panel-header {
    min-height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .title {
      flex-grow: 1; }
    & > *:not(:last-child) {
      margin-right: 1rem; } }
  &.disabled {
    .collapsible-panel-header {
      cursor: initial; }
    div {
      opacity: 0.5; } }

  .collapsible-panel-body {
    pointer-events: none; }
  &.open {
    .collapsible-panel-body {
      pointer-events: all; }
    .control-icon-container {
      .close-control-icon {
        opacity: 1; }
      .open-control-icon {
        opacity: 0; } } }

  .collapsible-panel-body-container {
    overflow: hidden;
    transition-property: height; }
  .control-icon-container {
    position: relative;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    flex-shrink: 1;
    &  > * {
      position: absolute;
      right: 0; }
    .close-control-icon {
      opacity: 0; }
    .open-control-icon {
      opacity: 1; } }


  // STYLED
  &.collapsible-panel-style-minimal, &.collapsible-panel-style-standard, &.collapsible-panel-style-outsize {
    width: 100%;
    .collapsible-panel-header {
      transition-property: background-color;
      transition-property: border-radius;
      letter-spacing: 0.02rem;
      .title {
        font-weight: 600; } }
    .collapsible-panel-body {
      padding: 0 1rem; } }


  // MINIMAL STYLE
  &.collapsible-panel-style-minimal {
    .collapsible-panel-header {
      @include border-radius-lg();
      padding: 0.2rem 1rem 0.2rem 1.2rem;
      background-color: $col-secondary;
      color: $white; }
    &.open .collapsible-panel-header {
      color: #36577d;
      background-color: $blue-lighter; }
    .collapsible-panel-body {
      padding: 1rem 0 0; } }

  // STANDARD
  &.collapsible-panel-style-standard {
    @include box-shadow();
    border-radius: 25px;
    .collapsible-panel-header {
      padding: 0.2rem 1.5rem 0.2rem 1.7rem;
      min-height: 50px;
      border-radius: 25px;
      background: $white; }
    .collapsible-panel-body-container {
      background: $blue-light;
      border-radius: 0 0 25px 25px; }
    .collapsible-panel-body > *:first-child {
      padding: 1rem 0;
      @include border-top(); }

    &.open {
      box-shadow: none;
      .collapsible-panel-header {
        background: $blue-light;
        color: $col-secondary-dark;
        border-radius: 25px 25px 0 0; } } }

  // OUTSIZE
  &.collapsible-panel-style-outsize {
    @include box-shadow();
    @include border-radius-lg();
    .collapsible-panel-header {
      padding: 0.2rem 1.5rem 0.2rem 1.7rem;
      min-height: 50px;
      @include border-radius-lg();
      background: $white; }
    .collapsible-panel-body-container {
      background: $white;
      border-radius: 0 0 15px 15px; }
    .collapsible-panel-body {
      padding-bottom: 1rem; }

    &.open {
      .collapsible-panel-header {
        color: $col-secondary-dark;
        border-radius: 15px 15px 0 0; } } } }








