@import '@payaca/styles/variables.sass';
@import '@payaca/styles/mixins.sass';

.content-panel {
  background-color: $white;
  @include border-radius();
  padding: 1rem;
  border: none !important;

  &.box-shadow {
    @include box-shadow(); } }
