@import '@payaca/styles/variables.sass';

.validated-field-wrapper {
  &.invalid .input-wrapper, &.invalid .input-wrapper:focus-within {
    border-color: $red; }

  .validation-errors {
    display: none;
    padding-top: 0.3rem; }

  &.invalid {
    .validation-errors {
      display: block; }

    .feedback-message-error {

      &:not(:last-child) {
        margin-bottom: 0.2rem; } } } }
