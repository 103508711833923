@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.stepped-progress-tracker {
  .stepped-progress-tracker-inner {
    display: flex;
    width: 100%; }

  .step {
    text-align: center;
    flex-shrink: 1;
    position: relative;
    width: 0;

    .step-name {
      line-height: 1em;
      display: block; }

    .step-indicator {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      margin-bottom: 0.5rem; }

    .step-indicator-label {
      line-height: 2rem;
      font-size: 2rem;
      margin-top: 0.4rem; }

    .step-indicator.clickable {
      cursor: pointer; } }

  .step-connector {
    height: 3px;
    flex-grow: 1; } }

.step .step-indicator, .step-connector {
  background-color: $blue-light; }

.stepped-progress-tracker {
  .step.completed-step .step-indicator, .step-connector.completed-step-connector {
    background-color: $green-dark; }

  .step.current-step .step-indicator, .step-connector.current-step-connector {
    background-color: $blue; }


  &.vertical-view {
    .stepped-progress-tracker-inner {
      flex-direction: column; }

    padding: 0 !important;

    .step {
      width: 100%;
      flex: 0 0 auto;

      .step-inner {
        width: 100% !important;
        margin: 0 !important;
        display: flex;
        align-items: center;

        .step-name {
          margin: 0 !important;
          text-align: left; }

        .step-indicator {
          margin-right: 0.5rem;
          margin-bottom: 0;
          flex-shrink: 0; } } }

    .step-connector {
      height: calc(40px + 0.5rem);
      flex-grow: 1;
      width: 3px;
      margin: -20px 0 -20px 18px !important; } } }
