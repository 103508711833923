.titled-content-panel {

    .title {
        h4 {
            margin: 0; } }

    > * {
        margin: 0;

        &:not(:last-child) {

            margin-bottom: 1rem; } } }
