@import '@payaca/styles/mixins.sass';
@import '@payaca/styles/variables.sass';

.button.v2 {
  @include border-radius-lg();
  font-family: inherit;
  padding: 0 16px;
  height: 30px;
  line-height: 1em;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.02rem;
  font-variant-ligatures: none;
  font-variant: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: relative;
  color: $blue-darker;
  fill: $blue-darker;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  box-shadow: none;

  &:before {
    transition: border 0.3s ease-in-out;
    content: '';
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit; }

  &.box-shadow {
    @include box-shadow(); }

  &:focus {
    outline: 0; }

  > {
    *:not(:last-child) {
      margin-right: 0.8em; }

    .button-content {
      flex-grow: 1; }

    .icon {
      flex-shrink: 1; } }

  &:hover {
    box-shadow: none; }

  &.disabled {
    cursor: default;
    box-shadow: none;
    opacity: 0.8; }

  .spinner-container {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    align-items: center; }

  &.processing {
    .button-content, .icon {
      visibility: hidden; } }

  &:not(.button-style-anchor) {
    &.button-col-primary {
      background-color: $col-primary;

      &:hover {
        background-color: $col-primary-dark; }

      &.disabled {
        background-color: rgba($col-primary, 0.5);
        color: rgba($col-secondary, 0.8);
        fill: rgba($col-secondary, 0.8); } }

    &.button-col-grey {
      background-color: $blue-lighter;

      &:hover {
        background-color: $blue-light; }

      &.disabled {
        background-color: rgba($blue-lighter, 0.5);
        color: rgba($col-secondary, 0.8);
        fill: rgba($col-secondary, 0.8); } }

    &.button-col-secondary {
      color: $white;
      fill: $white;
      background-color: $col-secondary;

      &:hover {
        background-color: $col-secondary-dark; }

      &.disabled {
        background-color: rgba($col-secondary, 0.5); } }

    &.button-col-white {
      background-color: $white;

      &:hover {
        background-color: $white; }

      &.disabled {
        background-color: $blue-lighter;
        color: rgba($col-secondary, 0.8);
        fill: rgba($col-secondary, 0.8); } } }

  &.button-style-circular {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 0;
    @include center-content();

    .icon {
      display: none; } }

  &.button-style-outsize {
    height: 50px;
    @include border-radius();
    padding: 0 20px; }

  &.button-style-anchor {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    text-decoration: underline;
    letter-spacing: 0;
    height: auto;

    > *:not(:last-child) {
      margin-right: 0.25em; }


    &.button-col-primary {
      color: $col-primary;
      fill: $col-primary;

      &:hover {
        color: $col-primary-dark;
        fill: $col-primary-dark; }

      &.disabled {
        color: rgba($col-primary, 0.5);
        fill: rgba($col-primary, 0.5); } }

    &.button-col-secondary {
      color: $col-secondary;
      fill: $col-secondary;

      &:hover {
        color: $col-secondary-dark;
        fill: $col-secondary-dark; }

      &.disabled {
        color: rgba($col-secondary, 0.5);
        fill: rgba($col-secondary, 0.5); } }

    &.button-col-white {
      color: $white;
      fill: $white;

      &:hover {
        color: $white;
        fill: $white; }

      &.disabled {
        color: rgba($white, 0.5);
        fill: rgba($white, 0.5); } } }

  &.outlined:not(.button-style-anchor) {
    background-color: transparent;
    color: $col-secondary;
    fill: $col-secondary;

    &:before {
      border-color: $col-secondary; }

    &:hover {
      background-color: inherit;
      color: $col-secondary-dark;
      fill: $col-secondary-dark;

      &:before {
        border-color: $col-secondary-dark; } }

    &.disabled {
      background-color: transparent;
      color: rgba($col-secondary, 0.5);
      fill: rgba($col-secondary, 0.5);

      &:before {
        border-color: rgba($col-secondary, 0.5); } }

    &.button-col-primary {
      &:before {
        border-color: $col-primary; }
      &:hover {

        &:before {
          border-color: $col-primary-dark; } }

      &.disabled {
        &:before {
          border-color: rgba($col-primary, 0.5); } } }

    &.button-col-grey {
      &:before {
        border-color: $blue-lighter; }
      &:hover {

        &:before {
          border-color: $blue-light; } }

      &.disabled {
        &:before {
          border-color: rgba($blue-lighter, 0.5); } } }

    &.button-col-white {
      &:before {
        border-color: $white; }
      &:hover {

        &:before {
          border-color: $blue-lighter; } } } }


  &.create-button {
    padding-left: 0;

    .create-icon-wrapper {
      height: 30px;
      width: 30px;
      padding: 4px 0 4px 4px;
      margin-right: 0.5rem;

      svg {
        width: 22px;
        height: 22px; } } }

  &.icon-button {
    .button-content {
      svg {
        width: 1.5rem;
        height: 1.5rem;

        &.react-iconly {

          width: 30px;
          height: 30px; } } } } }

.icon-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 75px;
  padding: 2px;

  .icon {
    &-sm.v2 {
      width: 30px;
      height: 30px;

      svg {
        width: 15px!important;
        height: 15px!important; } } }

  > span {
    text-align: center; }

  >*:not(:last-child) {
    margin-bottom: 0.5em; } }
