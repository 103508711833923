.mini-loader {
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-top: 2px solid #ffdb71;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

